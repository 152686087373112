// src/articles/DriversLicenseDocuments.js
import React from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import './Article.css';

const DriversLicenseDocuments = () => {
    return (
        <div className="article-page">
            <Helmet>
                <title>Required Documents for TN Driver’s License</title>
                <meta 
                    name="description" 
                    content="A comprehensive guide for new Tennessee residents on the essential documents required from various states to obtain a TN driver’s license." 
                />
                <meta 
                    name="keywords" 
                    content="Tennessee driver's license, TN license requirements, new residents TN, driver's license documents, Tennessee DMV" 
                />
            </Helmet>
            <h1>Residents from These States Need Certain Documents When Applying for a TN Driver’s License</h1>
            <p className="article-meta">By Caleb Wethington | Published: Nov. 15, 2024</p>
            <img 
                src={`${process.env.PUBLIC_URL}/TNLics.png`} 
                alt="TN Driver’s License Requirements" 
                className="article-image"
            />
            
            <p>
                The Tennessee Department of Safety and Homeland Security will soon require certain documents from residents of nine states when applying for a Tennessee driver’s license.
            </p>
            
            <p>
                Starting <strong>Jan. 10, 2025</strong>, new or returning Tennessee residents with a license from California, Connecticut, Illinois, Kentucky, Maine, Nevada, Oklahoma, Vermont, or West Virginia must present a Motor Vehicle Record (MVR) from their former state when applying for a Tennessee license.
            </p>
            
            <p>
                All other states, including the Volunteer State, participate in the American Association of Motor Vehicle Administrators’ State-to-State Verification Service (S2S), which allows participating states to electronically access the complete driver history record of anyone applying for a driver’s license. This means individuals moving from one of those 41 participating states won’t need to have an MVR to qualify for a Tennessee license.
            </p>
            
            <blockquote>
                “Through the S2S system, states can share real-time information about individual drivers, including convictions and license withdrawals. By providing a driver’s complete history, states can reduce the risk of fraud and improve safety by keeping dangerous drivers off the roads. Additionally, S2S enhances accuracy and reduces the processing time for obtaining a new credential in Tennessee,” the department said in a media release.
            </blockquote>
            
            <p>
                So, after the implementation date, individuals from the nine non-participating states must obtain an MVR from their former state before applying for a Tennessee driver’s license or ID.
            </p>
            
            <p>
                “The MVR must have been issued within 30 days before its presentation at a Driver Services Center. This MVR is required alongside other necessary documents to apply for a Tennessee credential,” the department added.
            </p>
            
            <p>
                For more information, visit the <Link to="https://www.tn.gov/safety" target="_blank" rel="noopener noreferrer">Tennessee Department of Safety and Homeland Security</Link> website.
            </p>
            
            <p><em>Copyright 2024 WSMV. All rights reserved.</em></p>
        </div>
    );
};

export default DriversLicenseDocuments;
