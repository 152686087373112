// src/articles/BuyingWaterfrontProperty.js
import React from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import './Article.css';

const BuyingWaterfrontProperty = () => {
    return (
        <div className="article-page">
            <Helmet>
                <title>Buying Waterfront Property In Tennessee</title>
                <meta 
                    name="description" 
                    content="Explore the opportunities and considerations when purchasing waterfront property in Tennessee, from scenic lakes to serene riversides." 
                />
                <meta 
                    name="keywords" 
                    content="Tennessee waterfront property, buying waterfront land, Tennessee real estate, lakeside property, riverside homes" 
                />
            </Helmet>
            <h1>Buying Waterfront Property In Tennessee</h1>
            <p className="article-meta">By Relocate-TN.com Admin | October 10, 2024</p>
            <img 
                src={`${process.env.PUBLIC_URL}/Waterfront.png`} 
                alt="Buying Waterfront Property in Tennessee" 
                className="article-image"
            />
            
            <p>
                If you are planning to purchase undeveloped lake front land or purchase an existing lake front homesite that does not yet have a dock, the process of building a boat dock is much more complicated than just including that cost into your budget. Your Homeowners Association (if there is one) may have certain structural requirements and the permitting process can be complicated. Existing permits DO NOT automatically transfer to a new owner. Ask your realtor about any special requirements that are unique to the purchase. The permitting process is explained below.
            </p>
            
            <h2>If You’re Buying Waterfront Property:</h2>
            <ul>
                <li>
                    <strong>TVA Ownership:</strong> TVA owns land or land rights (such as a right to flood the land or prevent certain construction) along most reservoir shoreline. It’s important to understand the TVA land and land rights before you buy waterfront property. Not sure? You can contact TVA’s Public Land Information Center at (800) 882-5263 and request information about your location.
                </li>
                <li>
                    <strong>Section 26a Permit:</strong> Request a copy of the Section 26a permit from the homeowner or your realtor. You can also contact TVA’s Public Land Information Center at (800) 882-5263 and request a copy of the Section 26a permit.
                </li>
                <li>
                    <strong>Permit Review:</strong> Review the permit with the seller or your realtor to ensure all facilities (docks, ramps, seawalls, land-based structures, utilities, etc.) are listed on the permit. Walk the site with the seller or your realtor.
                </li>
                <li>
                    <strong>TVA Approval for Construction:</strong> All construction or alterations to the shoreline require TVA approval, even minor changes to the dock. Be sure the plans in the permit match what is constructed on site.
                </li>
                <li>
                    <strong>TVA Land Rights Check:</strong> If there is TVA land or land rights between the listed property and the lake, check to be sure that all the construction on the TVA land and land rights has been approved by TVA.
                </li>
                <li>
                    <strong>Dock Construction Restrictions:</strong> Second stories on docks can be constructed as an open deck with railing, but they cannot be covered with a roof or enclosed with siding or screening. If the second story of a dock is covered, it will likely have to be removed.
                </li>
                <li>
                    <strong>Permit Transfer:</strong> Permits do not automatically transfer with property ownership. The new owner is required to apply to TVA for a Section 26a permit within 60 days of closing on the property.
                </li>
                <li>
                    <strong>Application Upon Closing:</strong> When you close on the property, remember to apply to TVA to obtain a new permit in your name.
                </li>
            </ul>
            
            <h2>If You’re Selling/Listing Waterfront Property:</h2>
            <ul>
                <li>
                    <strong>TVA Ownership:</strong> TVA owns land or land rights (such as a right to flood the land or prevent certain construction) along most reservoir shoreline. It’s important to understand the TVA land and land rights before you buy waterfront property. Not sure? You can contact TVA’s Public Land Information Center at (800) 882-5263 and request information about your location.
                </li>
                <li>
                    <strong>Shoreline Construction Approval:</strong> Ensure that all shoreline construction has been approved by TVA.
                </li>
                <li>
                    <strong>Section 26a Permit:</strong> If you can’t locate a Section 26a permit for the lot, contact TVA’s Public Land Information Center at (800) 882-5263 and request a copy of the Section 26a permit.
                </li>
                <li>
                    <strong>Permit Review:</strong> Review the permit and check to see whether all shoreline construction (docks, ramps, seawalls, land-based structures, utilities, etc.) is listed on the permit. Walk the site and be sure the plans in your permit match what is constructed on site.
                </li>
                <li>
                    <strong>TVA Land Rights Check:</strong> If there is TVA land or land rights between your lot and the lake, check to be sure that all the construction on the TVA land and land rights has been approved by TVA.
                </li>
                <li>
                    <strong>Shoreline Construction Approval:</strong> All construction or alterations to the shoreline require TVA approval, even minor changes to the dock.
                </li>
                <li>
                    <strong>Vegetation Management:</strong> Vegetation management of TVA property also requires a Section 26a permit.
                </li>
                <li>
                    <strong>Dock Construction Restrictions:</strong> Second stories on docks can be constructed as an open deck with railing, but they cannot be covered with a roof or enclosed with siding or screening. If the second story of a dock is covered, it will likely have to be removed.
                </li>
                <li>
                    <strong>Unauthorized Modifications:</strong> If you identify unauthorized modifications or construction, apply to TVA for approval for those structures. TVA will let you know if they can be approved, or whether modification or removal of the structure is required.
                </li>
            </ul>
            
            <h2>TVA Can Also Help You Determine:</h2>
            <ul>
                <li>If the property owner has the necessary rights to apply</li>
                <li>If a Section 26a permit is required for a specific land- or water-based activity</li>
                <li>The maximum facility size allowed at the location</li>
                <li>If there are any building restrictions on the private property</li>
                <li>If TVA holds any land rights or restrictions on the private property, such as a right to prevent certain construction</li>
            </ul>
            
            <p>
                Information Obtained from TVA can be accessed by visiting the following link:
            </p>
            
            <p>
                <a 
                    href="https://www.tva.com/environment/shoreline-construction-permits/buying-or-selling-waterfront-property" 
                    target="_blank" 
                    rel="noopener noreferrer nofollow"
                >
                    TVA Buying or Selling
                </a>
            </p>
            
            <img 
                src={`${process.env.PUBLIC_URL}/Flow.png`} 
                alt="Flowchart for Buying Waterfront Property" 
                className="article-image"
            />
        </div>
    );
};

export default BuyingWaterfrontProperty;
