const MiddleEastTNCities = [
  {
    name: 'Alexandria (Incorporated)',
    county: 'DeKalb County',
    zip: 37012,
    government: 'https://townofalexandria.us/index.html',
    demographics: 'https://data.census.gov/profile/Alexandria_town,_Tennessee?g=160XX00US4700620',
    electric: 'https://www.caneyforkec.com/',
    waterSewer: 'https://www.townofalexandria.us/water--sewer-department.html',
    cableInternet: 'STARLINK, DTC Communications',
    gas: 'https://www.mtng.com/service-area/',
    trashRemoval: 'https://www.dekalbtennessee.com/solid-waste-management.html',
    publicSchools: [
      'https://www.dekalbschools.net/',
      'https://www.alexschools.org/',
    ],
  },
  {
    name: 'Algood (Incorporated)',
    county: 'Putnam County',
    zip: 38501,
    government: 'https://algood-tn.com/',
    demographics: 'https://data.census.gov/profile/Algood_city,_Tennessee?g=160XX00US4700640',
    electric: 'https://www.ucemc.com/',
    waterSewer: 'https://algood-tn.com/index.php/watersewersanitation/',
    cableInternet: 'STARLINK, Spectrum',
    gas: 'https://www.mtng.com/service-area/',
    trashRemoval: 'https://algood-tn.com/index.php/watersewersanitation/',
    publicSchools: [
      'https://www.pcsstn.com/',
    ],
  },
  {
    name: 'Allardt (Incorporated)',
    county: 'Fentress County',
    zip: 38504,
    government: 'https://www.facebook.com/allardttn/',
    demographics: 'https://data.census.gov/profile/Allardt_city,_Tennessee?g=160XX00US4700660',
    electric: 'https://vec.org/locations/jamestown/',
    waterSewer: 'https://www.facebook.com/allardttn/',
    cableInternet: 'https://broadbandnow.com/Twin-Lakes-Telephone-Cooperative-Corporation',
    gas: 'No Website Available',
    trashRemoval: 'https://fentresscountytn.gov/solid-waste/',
    publicSchools: [
      'https://www.fentressboe.com/',
      'https://www.greatschools.org/tennessee/jamestown/289-Allardt-Elementary-School/',
    ],
  },
  {
    name: 'Altamont (Incorporated)',
    county: 'Grundy County',
    zip: 37301,
    government: 'https://altamont.city/',
    demographics: 'https://data.census.gov/profile/Altamont_town,_Tennessee?g=160XX00US4700940',
    electric: 'https://www.svalleyec.com/',
    waterSewer: 'https://bigcreekutilitydistrict.nexbillpayonline.com/',
    cableInternet: 'https://benlomandconnect.com/',
    gas: 'https://www.mtng.com/service-area/',
    trashRemoval: 'https://www.republicservices.com/locations/tennessee/altamont-trash-pickup-and-recycling',
    publicSchools: [
      'https://www.grundycoschools.com/',
    ],
  },
  {
    name: 'Apison',
    county: 'Hamilton County',
    zip: 37302,
    government: 'No Website Located',
    demographics: 'https://data.census.gov/profile/Apison_CDP,_Tennessee?g=160XX00US4701480',
    electric: 'https://svalleyec.com/',
    waterSewer: 'https://www.eastsideutility.com/',
    cableInternet: 'Xfinity, Brightspeed, STARLINK',
    gas: 'https://www.chattanoogagas.com/',
    trashRemoval: 'https://www.republicservices.com/locations/tennessee/apison-trash-pickup-and-recycling',
    publicSchools: [
      'https://apison.hcde.org/',
      'https://www.greatschools.org/tennessee/apison/',
    ],
  },
  {
    name: 'Athens (Incorporated)',
    county: 'McMinn County',
    zip: 37303,
    government: 'https://cityofathenstn.com/',
    demographics: 'https://data.census.gov/profile/Athens_city,_Tennessee?g=160XX00US4702320',
    electric: 'https://www.aub.org/',
    waterSewer: 'https://www.aub.org/',
    cableInternet: 'AT&T, Xfinity, STARLINK',
    gas: 'https://www.aub.org/',
    trashRemoval: 'https://www.cityofathenstn.com/team_athens/city_manager/public_works/divisions/sanitation.php',
    publicSchools: [
      'https://www.athenscityschools.net/',
      'https://www.mcminn.k12.tn.us/',
    ],
  },
  {
    name: 'Baxter (Incorporated)',
    county: 'Putnam County',
    zip: 38544,
    government: 'https://cityofbaxter.com/',
    demographics: 'https://data.census.gov/profile/Baxter_town,_Tennessee?g=160XX00US4703680',
    electric: 'https://www.ucemc.com/',
    waterSewer: 'https://cityofbaxter.com/water_sewer.html',
    cableInternet: 'Frontier, Spectrum, STARLINK',
    gas: 'https://www.mtng.com/',
    trashRemoval: 'https://putnamcountytn.gov/solid-waste-and-recycling',
    publicSchools: [
      'https://www.pcsstn.com/',
      'https://www.greatschools.org/tennessee/baxter/',
    ],
  },
  {
    name: 'Byrdstown (Incorporated)',
    county: 'Pickett County',
    zip: 38549,
    government: 'https://www.facebook.com/byrdstowntennessee/',
    demographics: 'https://data.census.gov/profile/Byrdstown_town,_Tennessee?g=160XX00US4710180',
    electric: 'https://vec.org/locations/byrdstown/',
    waterSewer: 'https://byrdstownwater.com/',
    cableInternet: 'https://broadbandnow.com/Twin-Lakes-Telephone-Cooperative-Corporation',
    gas: 'No Website Available',
    trashRemoval: 'https://nearestlandfill.com/org/pickett-county-landf',
    publicSchools: [
      'https://www.pickettk12.net/',
      'https://www.greatschools.org/tennessee/byrdstown/pickett-county-school-district/',
    ],
  },
  {
    name: 'Calhoun (Incorporated)',
    county: 'McMinn County',
    zip: 37309,
    government: 'http://www.calhounrivertown.com/',
    demographics: 'https://data.census.gov/profile/Calhoun_town,_Tennessee?g=160XX00US4710440',
    electric: 'https://www.countyoffice.org/calhoun-charleston-utility-calhoun-tn-19c/',
    waterSewer: 'https://www.countyoffice.org/calhoun-charleston-utility-calhoun-tn-19c/',
    cableInternet: 'Spectrum, STARLINK',
    gas: 'https://www.amerigas.com/locations/propane-service-areas/tennessee/calhoun',
    trashRemoval: 'https://www.countyoffice.org/calhoun-charleston-utility-calhoun-tn-19c/',
    publicSchools: [
      'https://www.calhounminks.com/',
      'https://www.greatschools.org/tennessee/calhoun/796-Calhoun-Elementary-School/',
    ],
  },
  {
    name: 'Celina (Incorporated)',
    county: 'Clay County',
    zip: 38551,
    government: 'https://www.cityofcelinatn.com/',
    demographics: 'https://data.census.gov/profile/Celina_city,_Tennessee?g=160XX00US4712100',
    electric: 'https://www.tcemc.org/celina-electric-office/',
    waterSewer: 'https://www.cityofcelinatn.com/files/',
    cableInternet: 'STARLINK',
    gas: 'https://www.mtng.com/service-area/',
    trashRemoval: 'https://www.cityofcelinatn.com/trash-collection-division',
    publicSchools: [
      'https://www.clayedu.com/home/',
      'https://www.greatschools.org/tennessee/celina/2336-Clay-County-High-School/',
    ],
  },
  {
    name: 'Charleston (Incorporated)',
    county: 'Bradley County',
    zip: 37310,
    government: 'https://www.charlestoncitytn.com/#/',
    demographics: 'https://data.census.gov/profile/Charleston_city,_Tennessee?g=160XX00US4713020',
    electric: 'https://vec.org/',
    waterSewer: 'https://ccudtn.nexbillpayonline.com/',
    cableInternet: 'AT&T, Spectrum, STARLINK',
    gas: 'No Website Available',
    trashRemoval: 'https://capwasteservices.com/',
    publicSchools: [
      'https://www.bradleyschools.org/',
      'https://www.greatschools.org/tennessee/charleston/',
    ],
  },
  {
    name: 'Chattanooga (Incorporated)',
    county: 'Hamilton County',
    zip: '37401-37412, 37415, 37416, 37419, 37421, 37450',
    government: 'https://chattanooga.gov/',
    demographics: 'https://data.census.gov/profile/Chattanooga_city,_Tennessee?g=160XX00US4714000',
    electric: 'https://epb.com/energy/',
    waterSewer: 'https://www.eastsideutility.com/',
    cableInternet: 'https://epb.com/fi-speed-internet/',
    gas: 'https://www.chattanoogagas.com/',
    trashRemoval: 'https://capwasteservices.com/chattanooga-service-page/',
    publicSchools: [
      'https://www.hcde.org/',
      'https://www.greatschools.org/tennessee/chattanooga/',
    ],
  },
  {
    name: 'Clarkrange',
    county: 'Fentress County',
    zip: 38553,
    government: 'No Website Located',
    demographics: 'https://data.census.gov/profile/Clarkrange_CDP,_Tennessee?g=160XX00US4715120',
    electric: 'https://vec.org/',
    waterSewer: 'https://waterzen.com/water-providers/fentress-county-utility-district/',
    cableInternet: 'Earthlink, STARLINK',
    gas: 'https://www.amerigas.com/locations/propane-service-areas/tennessee/clarkrange',
    trashRemoval: 'https://fentresscountytn.gov/solid-waste/',
    publicSchools: [
      'https://www.fentressboe.com/',
      'https://www.greatschools.org/tennessee/jamestown/289-Allardt-Elementary-School/',
      'https://www.greatschools.org/tennessee/clarkrange/',
    ],
  },
  {
    name: 'Cleveland (Incorporated)',
    county: 'Bradley County',
    zip: '37311, 37312, 37323',
    government: 'https://clevelandtn.gov/',
    demographics: 'https://data.census.gov/profile/Cleveland_city,_Tennessee?g=160XX00US4715400',
    electric: 'https://vec.org/locations/cleveland/',
    waterSewer: 'https://www.ocoeeutility.com/',
    cableInternet: 'Xfinity, AT&T, Earthlink',
    gas: 'https://www.blossmangas.com/locations/blossman-gas-cleveland-tn/',
    trashRemoval: 'https://www.clevelandtn.gov/183/Garbage-Solid-Waste',
    publicSchools: [
      'https://www.clevelandschools.org/',
      'https://www.greatschools.org/tennessee/cleveland/',
    ],
  },
  {
    name: 'Coalmont (Incorporated)',
    county: 'Grundy County',
    zip: 37313,
    government: 'https://www.grundycountytn.net/coalmont/index.html',
    demographics: 'https://data.census.gov/profile/Coalmont_city,_Tennessee?g=160XX00US4715920',
    electric: 'https://svalleyec.com/',
    waterSewer: 'https://bigcreekutilitydistrict.nexbillpayonline.com/',
    cableInternet: 'https://benlomandconnect.com/',
    gas: 'https://www.mtng.com/service-area/',
    trashRemoval: 'https://www.grundycountytn.net/coalmont/index.html',
    publicSchools: [
      'https://www.grundycoschools.com/',
      'https://www.greatschools.org/tennessee/coalmont/',
    ],
  },
  {
    name: 'Collegedale (Incorporated)',
    county: 'Hamilton County',
    zip: 37315,
    government: 'https://www.collegedaletn.gov/',
    demographics: 'https://data.census.gov/profile/Collegedale_city,_Tennessee?g=160XX00US4716300',
    electric: ['https://vec.org/', 'https://plateauelectric.com/'],
    waterSewer: 'https://www.eastsideutility.com/',
    cableInternet: 'Xfinity, Brightspeed, STARLINK',
    gas: 'https://www.amerigas.com/locations/propane-service-areas/tennessee/collegedale',
    trashRemoval: 'https://www.collegedaletn.gov/departments/public_works_department/sanitation_schedule/garbage_pick_up.php',
    publicSchools: [
      'https://www.collegedaletn.gov/community/schools.php/',
      'https://www.greatschools.org/tennessee/collegedale/',
    ],
  },
  {
    name: 'Cookeville (Incorporated)',
    county: 'Putnam County',
    zip: '38501, 38506',
    government: 'https://www.cookeville-tn.gov/',
    demographics: 'https://data.census.gov/profile/Cookeville_city,_Tennessee?g=160XX00US4716920',
    electric: 'https://www.cookeville-tn.gov/191/Electric-Division',
    waterSewer: 'https://www.cookeville-tn.gov/water',
    cableInternet: 'Frontier, STARLINK',
    gas: 'https://www.cookeville-tn.gov/205/Gas-Division',
    trashRemoval: 'https://www.cookeville-tn.gov/228/Residential-Garbage-Collection',
    publicSchools: [
      'https://www.pcsstn.com/',
      'https://www.greatschools.org/tennessee/cookeville/',
    ],
  },
  {
    name: 'Crab Orchard (Incorporated)',
    county: 'Cumberland County',
    zip: 37723,
    government: 'No Website Located',
    demographics: 'https://data.census.gov/profile/Crab_Orchard_city,_Tennessee?g=160XX00US4717840',
    electric: 'https://vec.org/',
    waterSewer: 'https://craborchardutility.com/',
    cableInternet: 'Frontier, STARLINK',
    gas: 'https://www.mtng.com/service-area/',
    trashRemoval: 'https://cumberlandcountytn.gov/directory/solid-waste-management/',
    publicSchools: [
      'https://cos.ccschools.k12tn.net/',
      'https://www.greatschools.org/tennessee/crab-orchard/230-Crab-Orchard-Elementary-School/',
    ],
  },
  {
    name: 'Crossville (Incorporated)',
    county: 'Cumberland County',
    zip: '38555, 38558, 38571, 38572',
    government: 'https://crossvilletn.gov/',
    demographics: 'https://data.census.gov/profile/Crossville_city,_Tennessee?g=160XX00US4718540',
    electric: 'https://vec.org/locations/crossville/',
    waterSewer: 'https://crossvilletn.gov/department/water-resources/',
    cableInternet: 'Frontier, STARLINK',
    gas: 'https://www.mtng.com/offices/crossville/',
    trashRemoval: 'https://www.daytontn.net/departments/sanitation.php',
    publicSchools: [
      'https://www.ccschools.k12tn.net/',
      'https://www.greatschools.org/tennessee/crossville/',
    ],
  },
  {
    name: 'Dayton (Incorporated)',
    county: 'Rhea County',
    zip: 37321,
    government: 'https://www.daytontn.net/',
    demographics: 'https://data.census.gov/profile/Dayton_city,_Tennessee?g=160XX00US4719700',
    electric: 'https://www.daytontn.net/residents/utilities/electric/index.php',
    waterSewer: 'https://www.daytontn.net/residents/utilities/water/index.php',
    cableInternet: 'AT&T, Spectrum, STARLINK',
    gas: 'https://www.mtng.com/offices/dayton/',
    trashRemoval: 'https://www.daytontn.net/departments/sanitation.php',
    publicSchools: [
      'https://www.daytoncity.net/',
      'https://www.greatschools.org/tennessee/dayton/',
    ],
  },
  {
    name: 'Delano',
    county: 'Polk County',
    zip: 37325,
    government: 'No Website Located',
    demographics: 'https://data.census.gov/profile/Delano_CDP,_Tennessee?g=160XX00US4720080',
    electric: 'https://ocoeecountry.com/utilities-agencies/',
    waterSewer: 'https://ocoeecountry.com/utilities-agencies/',
    cableInternet: 'Xfinity, Viasat, STARLINK',
    gas: 'No Website Available',
    trashRemoval: 'https://ocoeecountry.com/utilities-agencies/',
    publicSchools: [
      'https://schools.scsk12.org/delano-es/',
      'https://www.greatschools.org/tennessee/memphis/850-Delano-Elementary-School/',
    ],
  },
  {
    name: 'Dunlap (Incorporated)',
    county: 'Sequatchie County',
    zip: 37327,
    government: 'https://dunlaptn.gov/',
    demographics: 'https://data.census.gov/profile/Dunlap_city,_Tennessee?g=160XX00US4722000',
    electric: 'https://svalleyec.com/',
    waterSewer: 'https://waterzen.com/water-providers/dunlap-water/',
    cableInternet: 'https://bledsoe.net/',
    gas: 'https://www.doxo.com/info/dunlap-natural-gas-system',
    trashRemoval: 'https://sequatchiecountytn.gov/notices/sequatchie-county-sanitation-schedule/',
    publicSchools: [
      'https://www.sequatchieschools.net/',
      'https://www.greatschools.org/tennessee/dunlap/sequatchie-county-school-district/',
    ],
  },
  {
    name: 'East Cleveland',
    county: 'Bradley County',
    zip: 37311,
    government: 'https://www.clevelandtn.gov/',
    demographics: 'https://data.census.gov/profile/East_Cleveland_CDP,_Tennessee?g=160XX00US4722500',
    electric: 'https://www.clevelandutilities.com/',
    waterSewer: 'https://www.clevelandutilities.com/',
    cableInternet: 'https://www.clevelandutilities.com/',
    gas: 'No Website Available',
    trashRemoval: 'https://clevelandtn.gov/183/Garbage-Solid-Waste',
    publicSchools: [
      'https://www.clevelandschools.org/page/about-us/',
      'https://www.greatschools.org/tennessee/cleveland/99-Taylor-Elementary-School/',
    ],
  },
  {
    name: 'East Ridge (Incorporated)',
    county: 'Hamilton County',
    zip: 37412,
    government: 'https://www.eastridgetn.gov/',
    demographics: 'https://data.census.gov/profile/East_Ridge_city,_Tennessee?g=160XX00US4722720',
    electric: 'https://www.oakridgetn.gov/194/Oak-Ridge-Electric',
    waterSewer: 'https://www.amwater.com/tnaw/customer-service-billing/for-new-customers/',
    cableInternet: 'https://epb.com/',
    gas: 'https://customerportal.southerncompany.com/StartService/CreateStartService?LDC=1',
    trashRemoval: 'https://www.eastridgetn.gov/sanitation.html',
    publicSchools: [
      'https://erhs.hcde.org/',
    ],
  },
  {
    name: 'Englewood (Incorporated)',
    county: 'McMinn County',
    zip: 37329,
    government: 'https://townofenglewood.com/',
    demographics: 'https://data.census.gov/profile/Englewood_town,_Tennessee?g=160XX00US4724040',
    electric: 'https://www.tvec.com/',
    waterSewer: 'https://townofenglewood.com/departments/utilities/water/index.html',
    cableInternet: 'Viasat, STARLINK, Xfinity',
    gas: 'https://townofenglewood.com/departments/utilities/natural_gas/index.html',
    trashRemoval: 'https://www.mcminncountytn.gov/county_landfill.html',
    publicSchools: [
      'https://www.mcminn.k12.tn.us/',
      'https://www.greatschools.org/tennessee/englewood/',
    ],
  },
  {
    name: 'Etowah (Incorporated)',
    county: 'McMinn County',
    zip: 37331,
    government: 'https://www.cityofetowahtn.com/',
    demographics: 'https://data.census.gov/profile/Etowah_city,_Tennessee?g=160XX00US4724480',
    electric: 'https://etowahutilities.com/services-products/electric/',
    waterSewer: 'https://etowahutilities.com/services-products/water/',
    cableInternet: 'Xfinity, Viasat, STARLINK',
    gas: 'https://etowahutilities.com/services-products/gas/',
    trashRemoval: 'https://www.cityofetowahtn.com/2203/Public-Works',
    publicSchools: [
      'https://www.etowahcityschool.com/',
      'https://www.greatschools.org/tennessee/etowah/',
    ],
  },
  {
    name: 'Fairfield Glade',
    county: 'Cumberland County',
    zip: '38555, 38558',
    government: 'https://fairfieldglade.net/our-hometown/',
    demographics: 'https://data.census.gov/profile/Fairfield_Glade_CDP,_Tennessee?g=160XX00US4724980',
    electric: 'https://vec.org/outage-central/outage-map/',
    waterSewer: 'https://craborchardutility.com/',
    cableInternet: 'Xfinity, Frontier, STARLINK',
    gas: 'https://www.mtng.com/service-area/',
    trashRemoval: 'https://cumberlandcountytn.gov/directory/solid-waste-management/',
    publicSchools: [
      'https://www.ccschools.k12tn.net/',
      'https://www.greatschools.org/tennessee/crossville/cumberland-county-school-district/',
    ],
  },
  {
    name: 'Falling Water',
    county: 'Hamilton County',
    zip: 37343,
    government: 'No Website Located',
    demographics: 'https://data.census.gov/profile/Falling_Water_CDP,_Tennessee?g=160XX00US4725560',
    electric: 'https://svalleyec.com/',
    waterSewer: 'https://www.nwud.net/',
    cableInternet: 'Earthlink, AT&T, Xfinity',
    gas: 'No Website Available',
    trashRemoval: 'https://recycle.hamiltontn.gov/',
    publicSchools: [
      'https://www.hcde.org/',
    ],
  },
  {
    name: 'Flat Top Mountain',
    county: 'Hamilton County',
    zip: '37373, 37379',
    government: 'No Website Located',
    demographics: 'https://data.census.gov/profile/Flat_Top_Mountain_CDP,_Tennessee?g=160XX00US4726490',
    electric: 'https://www.mountainelectric.com/',
    waterSewer: 'https://signalmountaintn.gov/departments/water_utility/index.php',
    cableInternet: 'Xfinity, STARLINK, EarthLink',
    gas: 'https://www.mtng.com/service/',
    trashRemoval: 'https://signalmountaintn.gov/departments/public_works/garbage_and_trash.php',
    publicSchools: [
      'https://www.hcde.org/district',
    ],
  },
  {
    name: 'Gainesboro (Incorporated)',
    county: 'Jackson County',
    zip: 38562,
    government: 'https://www.explorejctn.com/communities/gainesboro',
    demographics: 'https://data.census.gov/profile/Gainesboro_town,_Tennessee?g=160XX00US4728420',
    electric: 'https://www.ucemc.com/',
    waterSewer: 'https://jcutilitydistrict.com/',
    cableInternet: 'https://broadbandnow.com/Twin-Lakes-Telephone-Cooperative-Corporation',
    gas: 'https://www.mtng.com/service-area/',
    trashRemoval: 'No Website Located',
    publicSchools: [
      'https://www.facebook.com/jacksoncoschoolstn/',
      'https://www.greatschools.org/tennessee/gainesboro/jackson-county-school-district/',
    ],
  },
  {
    name: 'Graysville (Incorporated)',
    county: 'Rhea County',
    zip: 37338,
    government: 'https://graysvilletn.org/',
    demographics: 'https://data.census.gov/profile/Graysville_town,_Tennessee?g=160XX00US4730760',
    electric: 'https://www.tvec.com/',
    waterSewer: 'https://graysvilletn.org/',
    cableInternet: 'https://broadbandnow.com/Bledsoe-Telephone-Cooperative',
    gas: 'https://www.mtng.com/service-area/',
    trashRemoval: 'https://rheacountytn.org/relocation/recycling-centers/',
    publicSchools: [
      'https://www.rheacounty.org/schools',
    ],
  },
  {
    name: 'Grimsley',
    county: 'Fentress County',
    zip: 38565,
    government: 'No Website Located',
    demographics: 'https://data.census.gov/profile/Grimsley_CDP,_Tennessee?g=160XX00US4731420',
    electric: 'https://www.mte.com/',
    waterSewer: 'https://www.nwud.net/',
    cableInternet: 'Xfinity, STARLINK',
    gas: 'No Website Available',
    trashRemoval: 'https://fentresscountytn.gov/solid-waste/',
    publicSchools: [
      'https://www.fentressboe.com/',
      'https://www.greatschools.org/tennessee/jamestown/fentress-county-school-district/',
    ],
  },
  {
    name: 'Harriman (Incorporated)',
    county: 'Roane County',
    zip: 37748,
    government: 'https://cityofharriman.net/',
    demographics: 'https://data.census.gov/profile/Harriman_city,_Tennessee?g=160XX00US4732520',
    electric: 'https://hub-tn.com/',
    waterSewer: 'https://hub-tn.com/',
    cableInternet: 'Xfinity, STARLINK',
    gas: 'https://hub-tn.com/',
    trashRemoval: 'https://roanecountytn.gov/solid-waste-recycling/',
    publicSchools: [
      'https://www.roaneschools.com/',
      'https://www.greatschools.org/tennessee/harriman/',
    ],
  },
  {
    name: 'Harrison',
    county: 'Hamilton County',
    zip: 37341,
    government: 'No Website Located',
    demographics: 'https://data.census.gov/profile/Harrison_CDP,_Tennessee?g=160XX00US4732600',
    electric: 'https://vec.org/',
    waterSewer: 'https://svud.org/',
    cableInternet: 'AT&T, Xfinity, STARLINK',
    gas: 'https://www.chattanoogagas.com/',
    trashRemoval: 'https://recycle.hamiltontn.gov/',
    publicSchools: [
      'https://www.hcde.org/',
      'https://www.greatschools.org/tennessee/harrison/375-Central-High-School/',
    ],
  },
  {
    name: 'Huntsville (Incorporated)',
    county: 'Scott County',
    zip: 37756,
    government: 'https://townofhuntsville.com/',
    demographics: 'https://data.census.gov/profile/Huntsville_town,_Tennessee?g=160XX00US4736640',
    electric: 'https://plateauelectric.com/',
    waterSewer: 'https://huntsvilleutilitydistrict.com/',
    cableInternet: 'Xfinity, STARLINK',
    gas: 'https://citizensgastn.com/',
    trashRemoval: 'https://scottcounty.com/community/recycling-center/',
    publicSchools: [
      'https://www.scottcounty.net/',
      'https://www.greatschools.org/tennessee/huntsville/',
    ],
  },
  {
    name: 'Jamestown',
    county: 'Fentress County',
    zip: 38556,
    government: 'https://jamestowntn.gov/',
    demographics: 'https://data.census.gov/profile/Jamestown_city,_Tennessee?g=160XX00US4737780',
    electric: 'https://vec.org/locations/jamestown/',
    waterSewer: 'https://waterzen.com/water-providers/fentress-county-utility-district/',
    cableInternet: 'https://broadbandnow.com/Twin-Lakes-Telephone-Cooperative-Corporation',
    gas: 'No Website Available',
    trashRemoval: 'https://fentresscountytn.gov/solid-waste/',
    publicSchools: [
      'https://www.fentressboe.com/',
      'https://www.greatschools.org/tennessee/jamestown/',
    ],
  },
  {
    name: 'Jasper (Incorporated)',
    county: 'Marion County',
    zip: 37347,
    government: 'https://www.jasper-tn.com/',
    demographics: 'https://data.census.gov/profile/Jasper_town,_Tennessee?g=160XX00US4737820',
    electric: 'https://svalleyec.com/',
    waterSewer: 'https://www.jasper-tn.com/departments.html#water',
    cableInternet: 'Spectrum, STARLINK',
    gas: 'No Website Located',
    trashRemoval: 'https://www.republicservices.com/locations/tennessee/jasper',
    publicSchools: [
      'https://www.marionschools.org/',
      'https://www.greatschools.org/tennessee/jasper/marion-county-school-district/',
    ],
  },
  {
    name: 'Kimball (Incorporated)',
    county: 'Marion County',
    zip: 37347,
    government: 'https://townofkimball.com/',
    demographics: 'https://data.census.gov/profile/Kimball_town,_Tennessee?g=160XX00US4739400',
    electric: 'https://svalleyec.com/',
    waterSewer: 'https://www.southpittsburgutilities.com/',
    cableInternet: 'Spectrum, AT&T, STARLINK',
    gas: 'No Website Located',
    trashRemoval: 'https://nearestlandfill.com/org/marion-county-landfill/',
    publicSchools: [
      'https://www.marionschools.org/',
      'https://www.greatschools.org/tennessee/jasper/marion-county-school-district/',
    ],
  },
  {
    name: 'Kingston (Incorporated)',
    county: 'Roane County',
    zip: 37763,
    government: 'https://kingstontn.gov/',
    demographics: 'https://data.census.gov/profile/Kingston_city,_Tennessee?g=160XX00US4739620',
    electric: 'https://rockwoodelectric.com/account-options/',
    waterSewer: 'https://kingstontn.gov/water-department-2/',
    cableInternet: 'Xfinity, STARLINK',
    gas: 'https://orud.org/',
    trashRemoval: 'https://roanecountytn.gov/solid-waste-recycling/',
    publicSchools: [
      'https://www.roaneschools.com/',
      'https://www.greatschools.org/tennessee/kingston/roane-county-school-district/',
    ],
  },
  {
    name: 'Lake Tansi',
    county: 'Cumberland County',
    zip: 38572,
    government: 'https://laketansi-poa.com/',
    demographics: 'https://data.census.gov/profile/Lake_Tansi_CDP,_Tennessee?g=160XX00US4740560',
    electric: 'https://vec.org/',
    waterSewer: 'https://crossvilletn.gov/department/catoosa-utility-department/',
    cableInternet: 'Frontier, STARLINK',
    gas: 'https://www.mtng.com/service-area/',
    trashRemoval: 'https://cumberlandcountytn.gov/directory/solid-waste-management/',
    publicSchools: [
      'https://www.ccschools.k12tn.net/',
      'https://www.greatschools.org/tennessee/crossville/cumberland-county-school-district/',
    ],
  },
  {
    name: 'Lakesite (Incorporated)',
    county: 'Hamilton County',
    zip: 37379,
    government: 'https://www.lakesitetn.gov/',
    demographics: 'https://data.census.gov/profile/Lakesite_city,_Tennessee?g=160XX00US4740540',
    electric: 'https://epb.com/',
    waterSewer: 'https://www.lakesitetn.gov/community/utilities.php',
    cableInternet: 'AT&T, Xfinity, STARLINK',
    gas: 'https://www.chattanoogagas.com/',
    trashRemoval: 'https://www.lakesitetn.gov/community/garbage_service/index.php',
    publicSchools: [
      'https://www.hcde.org/',
      'https://www.greatschools.org/tennessee/chattanooga/hamilton-county-school-district/',
    ],
  },
  {
    name: 'Livingston (Incorporated)',
    county: 'Overton County',
    zip: 38570,
    government: 'https://www.cityoflivingston.net/',
    demographics: 'https://data.census.gov/profile/Livingston_town,_Tennessee?g=160XX00US4743140',
    electric: 'https://www.ucemc.com/',
    waterSewer: 'https://waterzen.com/water-providers/livingston-water-department/',
    cableInternet: 'Xfinity, STARLINK',
    gas: 'https://www.cityoflivingston.net/government',
    trashRemoval: 'https://overtoncountytn.gov/departments/recycling-waste-department/',
    publicSchools: [
      'https://www.oc-sd.com/',
      'https://www.greatschools.org/tennessee/livingston/',
    ],
  },
  {
    name: 'Lookout Mountain (Incorporated)',
    county: 'Hamilton County',
    zip: 37350,
    government: 'No Website Located',
    demographics: 'https://data.census.gov/profile/Lookout_Mountain_town,_Tennessee?g=160XX00US4743640',
    electric: 'https://epb.com/',
    waterSewer: 'https://www.amwater.com/tnaw/',
    cableInternet: 'https://epb.com/',
    gas: 'https://www.chattanoogagas.com/company/about-us.html',
    trashRemoval: 'https://recycle.hamiltontn.gov/',
    publicSchools: [
      'https://lookoutmountainschool.com/',
      'https://www.greatschools.org/tennessee/lookout-mountain/384-Lookout-Mountain-Elementary-School/',
    ],
  },
  {
    name: 'McDonald',
    county: 'Bradley County',
    zip: 37353,
    government: 'No Website Located',
    demographics: 'https://data.census.gov/profile/McDonald_CDP,_Tennessee?g=160XX00US4744700',
    electric: 'https://vec.org/billing-payments/online-payments/',
    waterSewer: 'https://www.ocoeeutility.com/',
    cableInternet: 'STARLINK, Spectrum',
    gas: 'https://www.mtng.com/',
    trashRemoval: 'https://www.republicservices.com/santek-waste-services',
    publicSchools: [
      'https://mcdonald.greenek12.org/',
      'https://www.greatschools.org/tennessee/mcdonald/',
    ],
  },
  {
    name: 'McMinnville (Incorporated)',
    county: 'Warren County',
    zip: 37110,
    government: 'https://www.mcminnvilletn.gov/',
    demographics: 'https://data.census.gov/profile/McMinnville_city,_Tennessee?g=160XX00US4745100',
    electric: 'https://www.caneyforkec.com/',
    waterSewer: 'https://mesystem.net/',
    cableInternet: 'https://broadbandnow.com/Ben-Lomand-Connect',
    gas: 'https://www.mtng.com/offices/mcminnville/',
    trashRemoval: 'https://www.mcminnvilletn.gov/departments/public_works/sanitation_services.php',
    publicSchools: [
      'https://www.warrenschools.com/',
      'https://www.greatschools.org/tennessee/mcminnville/',
    ],
  },
  {
    name: 'Middle Valley',
    county: 'Hamilton County',
    zip: 37343,
    government: 'No Website Located',
    demographics: 'https://data.census.gov/profile/Middle_Valley_CDP,_Tennessee?g=160XX00US4748360',
    electric: 'https://www.mte.com/',
    waterSewer: 'https://www.amwater.com/tnaw/',
    cableInternet: 'https://epb.com/',
    gas: 'https://www.mtng.com/',
    trashRemoval: 'https://recycle.hamiltontn.gov/',
    publicSchools: [
      'https://mves.hcde.org/',
      'https://www.hcde.org/district',
    ],
  },
  {
    name: 'Midtown',
    county: 'Roane County',
    zip: 37748,
    government: 'No Website Located',
    demographics: 'https://data.census.gov/profile/Midtown_CDP,_Tennessee?g=160XX00US4748460',
    electric: 'https://www.mte.com/',
    waterSewer: 'https://www.amwater.com/tnaw/',
    cableInternet: 'AT&T, Xfinity, STARLINK',
    gas: 'No Website Available',
    trashRemoval: 'https://roanecountytn.gov/solid-waste-recycling/',
    publicSchools: [
      'https://mtes.roaneschools.com/',
      'https://www.greatschools.org/tennessee/harriman/1232-Midtown-Elementary-School/',
    ],
  },
  {
    name: 'Monteagle (Incorporated)',
    county: 'Grundy County',
    zip: 37356,
    government: 'https://www.townofmonteagle-tn.gov/',
    demographics: 'https://data.census.gov/profile/Monteagle_town,_Tennessee?g=160XX00US4749740',
    electric: 'https://svalleyec.com/',
    waterSewer: 'https://www.townofmonteagle-tn.gov/?page_id=819',
    cableInternet: 'https://broadbandnow.com/Ben-Lomand-Connect',
    gas: 'https://www.mtng.com/',
    trashRemoval: 'No Website Located',
    publicSchools: [
      'https://www.monteagleelementary.org/',
      'https://www.greatschools.org/tennessee/monteagle/756-Monteagle-Elementary-School/',
    ],
  },
  {
    name: 'Monterey (Incorporated)',
    county: 'Putnam County',
    zip: 38574,
    government: 'https://townofmontereytn.com/',
    demographics: 'https://data.census.gov/profile/Monterey_town,_Tennessee?g=160XX00US4749760',
    electric: 'https://vec.org/locations/monterey/',
    waterSewer: 'https://townofmontereytn.com/water_distribution.html',
    cableInternet: 'https://broadbandnow.com/Twin-Lakes-Telephone-Cooperative-Corporation',
    gas: 'https://www.mtng.com/service-area/',
    trashRemoval: 'https://townofmontereytn.com/sanitation.html',
    publicSchools: [
      'https://www.pcsstn.com/',
      'https://www.greatschools.org/tennessee/cookeville/1214-Monterey-High-School/',
    ],
  },
  {
    name: 'Morrison (Incorporated)',
    county: 'Warren County',
    zip: 37357,
    government: 'https://morrisoncity.org/',
    demographics: 'https://data.census.gov/profile/Morrison_town,_Tennessee?g=160XX00US4750220',
    electric: 'https://www.caneyforkec.com/',
    waterSewer: 'https://wcudonline.com/',
    cableInternet: 'https://broadbandnow.com/Ben-Lomand-Connect',
    gas: 'https://www.mtng.com/service-area/',
    trashRemoval: 'https://www.warrencountytn.gov/departments/sanitation_department/index.php',
    publicSchools: [
      'https://www.warrenschools.com/',
      'https://www.greatschools.org/tennessee/morrison/',
    ],
  },
  {
    name: 'Mowbray Mountain',
    county: 'Hamilton County',
    zip: 37379,
    government: 'No Website Located',
    demographics: 'https://data.census.gov/profile/Mowbray_Mountain_CDP,_Tennessee?g=160XX00US4751402',
    electric: 'https://www.mountainelectric.com/',
    waterSewer: 'https://www.nwud.net/',
    cableInternet: 'https://epb.com/',
    gas: 'No Website Available',
    trashRemoval: 'https://recycle.hamiltontn.gov/',
    publicSchools: [
      'https://www.hcde.org/',
      'https://www.greatschools.org/tennessee/chattanooga/hamilton-county-school-district/',
    ],
  },
  {
    name: 'New Hope (Incorporated)',
    county: 'Marion County',
    zip: 37380,
    government: 'https://marioncountytn.net/new-hope',
    demographics: 'https://data.census.gov/profile/New_Hope_city,_Tennessee?g=160XX00US4752780',
    electric: 'https://svalleyec.com/',
    waterSewer: 'https://www.southpittsburgutilities.com/',
    cableInternet: 'Spectrum, AT&T, STARLINK',
    gas: 'No Website Located',
    trashRemoval: 'https://nearestlandfill.com/org/marion-county-landfill/',
    publicSchools: [
      'https://www.marionschools.org/',
      'https://www.greatschools.org/tennessee/jasper/marion-county-school-district/',
    ],
  },
  {
    name: 'Niota (Incorporated)',
    county: 'McMinn County',
    zip: 37826,
    government: 'https://www.cityofniota.org/',
    demographics: 'https://data.census.gov/profile/Niota_city,_Tennessee?g=160XX00US4753380',
    electric: 'https://vec.org/',
    waterSewer: 'https://www.cityofniota.org/niota-waterworks',
    cableInternet: 'STARLINK',
    gas: 'No Website Available',
    trashRemoval: 'https://www.mcminncountytn.gov/county_landfill.html',
    publicSchools: [
      'https://www.niotaschool.com/',
      'https://www.greatschools.org/tennessee/niota/802-Niota-Elementary-School/',
    ],
  },
  {
    name: 'Oak Ridge (Incorporated)',
    county: 'Roane County',
    zip: 37830,
    government: 'https://www.oakridgetn.gov/',
    demographics: 'https://data.census.gov/profile/Oak_Ridge_city,_Tennessee?g=160XX00US4755120',
    electric: 'https://www.oakridgetn.gov/194/Oak-Ridge-Electric',
    waterSewer: 'https://www.oakridgetn.gov/164/Utility-Business-Office',
    cableInternet: 'AT&T, Xfinity',
    gas: 'https://orud.org/',
    trashRemoval: 'https://www.oakridgetn.gov/207/Public-Works',
    publicSchools: [
      'https://www.ortn.edu/schools/',
      'https://www.greatschools.org/tennessee/oak-ridge/',
    ],
  },
  {
    name: 'Oliver Springs (Incorporated)',
    county: 'Roane County',
    zip: 37840,
    government: 'https://oliversprings-tn.gov/',
    demographics: 'https://data.census.gov/profile/Oliver_Springs_town,_Tennessee?g=160XX00US4755800',
    electric: 'https://www.roaneecd.com/sites/utilities/',
    waterSewer: 'https://oliversprings-tn.gov/952/Water-Department',
    cableInternet: 'Xfinity, STARLINK',
    gas: 'No Website Available',
    trashRemoval: 'https://oliversprings-tn.gov/901/Garbage-Services',
    publicSchools: [
      'https://oshs.roaneschools.com/',
      'https://www.greatschools.org/tennessee/oliver-springs/',
    ],
  },
  {
    name: 'Oneida (Incorporated)',
    county: 'Scott County',
    zip: 37841,
    government: 'https://www.townofoneida.com/',
    demographics: 'https://data.census.gov/profile/Oneida_town,_Tennessee?g=160XX00US4755860',
    electric: 'https://plateauelectric.com/',
    waterSewer: 'https://oneidawater.nexbillpayonline.com/',
    cableInternet: 'https://broadbandnow.com/Highland-HTC',
    gas: 'https://citizensgastn.com/',
    trashRemoval: 'https://www.scottsolidwaste.com/',
    publicSchools: [
      'https://www.oneidaschools.org/',
      'https://www.greatschools.org/tennessee/oneida/',
    ],
  },
  {
    name: 'Ooltewah',
    county: 'Hamilton County',
    zip: 37363,
    government: 'No Website Located',
    demographics: 'https://data.census.gov/profile/Ooltewah_CDP,_Tennessee?g=160XX00US4755900',
    electric: 'https://vec.org/',
    waterSewer: 'https://svud.org/',
    cableInternet: 'https://epb.com/',
    gas: 'https://www.chattanoogagas.com/',
    trashRemoval: 'https://recycle.hamiltontn.gov/',
    publicSchools: [
      'https://www.hcde.org/',
      'https://www.greatschools.org/tennessee/ooltewah/',
    ],
  },
  {
    name: 'Palmer (Incorporated)',
    county: 'Grundy County',
    zip: 37365,
    government: 'https://www.grundycountytn.net/palmer/index.html',
    demographics: 'https://data.census.gov/profile/Palmer_town,_Tennessee?g=160XX00US4756560',
    electric: 'https://svalleyec.com/',
    waterSewer: 'https://bigcreekutilitydistrict.nexbillpayonline.com/',
    cableInternet: 'https://benlomandconnect.com/',
    gas: 'https://www.mtng.com/service-area/',
    trashRemoval: 'No Website Located',
    publicSchools: [
      'https://www.grundycoschools.com/',
      'https://www.greatschools.org/tennessee/palmer/360-Palmer-Elementary-School/',
    ],
  },
  {
    name: 'Pikeville (Incorporated)',
    county: 'Bledsoe County',
    zip: 37367,
    government: 'https://pikevilletn.com/',
    demographics: 'https://data.census.gov/profile/Pikeville_city,_Tennessee?g=160XX00US4758120',
    electric: 'https://svalleyec.com/',
    waterSewer: 'https://pikevilletn.com/water_dept/',
    cableInternet: 'STARLINK, EarthLink',
    gas: 'https://pikevilletn.com/natural_gas/',
    trashRemoval: 'https://bledsoetn.com/',
    publicSchools: [
      'https://www.bledsoecountyschools.org/',
      'https://www.greatschools.org/tennessee/pikeville/',
    ],
  },
  {
    name: 'Pleasant Hill (Incorporated)',
    county: 'Cumberland County',
    zip: 38578,
    government: 'No Website Located',
    demographics: 'https://data.census.gov/profile/Pleasant_Hill_town,_Tennessee?g=160XX00US4759240',
    electric: 'https://vec.org/',
    waterSewer: 'https://www.hixsonutility.com/',
    cableInternet: 'https://broadbandnow.com/Ben-Lomand-Connect',
    gas: 'https://www.mtng.com/service-area/',
    trashRemoval: 'https://cumberlandcountytn.gov/directory/solid-waste-management/',
    publicSchools: [
      'https://www.ccschools.k12tn.net/',
      'https://www.greatschools.org/tennessee/sparta/236-Pleasant-Hill-Elementary-School/',
    ],
  },
  {
    name: 'Powells Crossroads (Incorporated)',
    county: 'Marion County',
    zip: 37397,
    government: 'https://marioncountytn.net/powells-crossroads/',
    demographics: 'https://data.census.gov/profile/Powells_Crossroads_town,_Tennessee?g=160XX00US4760500',
    electric: 'https://svalleyec.com/',
    waterSewer: 'https://www.southpittsburgutilities.com/',
    cableInternet: 'https://broadbandnow.com/SVEConnect',
    gas: 'No Website Located',
    trashRemoval: 'https://nearestlandfill.com/org/marion-county-landfill/',
    publicSchools: [
      'https://www.marionschools.org/',
      'https://www.greatschools.org/tennessee/jasper/marion-county-school-district/',
    ],
  },
  {
    name: 'Red Bank (Incorporated)',
    county: 'Hamilton County',
    zip: 37415,
    government: 'https://www.redbanktn.gov/',
    demographics: 'https://data.census.gov/profile/Red_Bank_city,_Tennessee?g=160XX00US4761960',
    electric: 'https://epb.com/',
    waterSewer: 'https://wwta.hamiltontn.gov/153/Red-Bank',
    cableInternet: 'https://epb.com/',
    gas: 'No Website Available',
    trashRemoval: 'https://redbanktn.gov/257/Solid-Waste',
    publicSchools: [
      'https://www.hcde.org/',
    ],
  },
  {
    name: 'Riceville',
    county: 'McMinn County',
    zip: 37370,
    government: 'No Website Located',
    demographics: 'https://data.census.gov/profile/Riceville_CDP,_Tennessee?g=160XX00US4762580',
    electric: 'https://vec.org/',
    waterSewer: 'https://ricevilleutilitydistrict.org/',
    cableInternet: 'Xfinity, STARLINK',
    gas: 'No Website Available',
    trashRemoval: 'https://www.mcminncountytn.gov/county_landfill.html',
    publicSchools: [
      'https://www.reswildcats.com/',
      'https://www.greatschools.org/tennessee/athens/803-Riceville-Elementary-School/',
    ],
  },
  {
    name: 'Rockwood (Incorporated)',
    county: 'Roane County',
    zip: 37854,
    government: 'https://cityofrockwood.com/',
    demographics: 'https://data.census.gov/profile/Rockwood_city,_Tennessee?g=160XX00US4764440',
    electric: 'https://cityofrockwood.com/rockwood-electric-utility',
    waterSewer: 'https://cityofrockwood.com/rockwood-water-sewer-gas',
    cableInternet: 'Xfinity, STARLINK',
    gas: 'https://cityofrockwood.com/rockwood-water-sewer-gas',
    trashRemoval: 'https://cityofrockwood.com/rockwood-public-works-solid-waste-policy',
    publicSchools: [
      'https://rhs.roaneschools.com/',
      'https://www.greatschools.org/tennessee/rockwood/',
    ],
  },
  {
    name: 'Sale Creek',
    county: 'Hamilton County',
    zip: '37304, 37373',
    government: 'No Website Located',
    demographics: 'https://data.census.gov/profile/Sale_Creek_CDP,_Tennessee?g=160XX00US4766220',
    electric: 'https://svalleyec.com/',
    waterSewer: 'https://www.nwud.net/',
    cableInternet: 'EarthLink, STARLINK',
    gas: 'No Website Available',
    trashRemoval: 'https://recycle.hamiltontn.gov/',
    publicSchools: [
      'https://www.hcde.org/',
      'https://scmhs.hcde.org/',
    ],
  },
  {
    name: 'Signal Mountain (Incorporated)',
    county: 'Hamilton County',
    zip: 37377,
    government: 'https://signalmountaintn.gov/',
    demographics: 'https://data.census.gov/profile/Signal_Mountain_town,_Tennessee?g=160XX00US4768540',
    electric: 'https://www.chattanoogagas.com/',
    waterSewer: 'https://signalmountaintn.gov/departments/water_utility/index.php',
    cableInternet: 'https://broadbandnow.com/EPB',
    gas: 'https://www.chattanoogagas.com/',
    trashRemoval: 'https://signalmountaintn.gov/departments/public_works/garbage_and_trash.php',
    publicSchools: [
      'https://www.hcde.org/',
      'https://www.greatschools.org/tennessee/signal-mountain/',
    ],
  },
  {
    name: 'Smithville (Incorporated)',
    county: 'DeKalb County',
    zip: 37166,
    government: 'https://www.smithvillecityhall.com/',
    demographics: 'https://data.census.gov/profile/Smithville_city,_Tennessee?g=160XX00US4769320',
    electric: 'https://www.caneyforkec.com/',
    waterSewer: 'https://www.smithvillecityhall.com/',
    cableInternet: 'https://broadbandnow.com/DTC-Communications',
    gas: 'https://www.mtng.com/service-area/',
    trashRemoval: 'https://www.dekalbtennessee.com/solid-waste-management.html',
    publicSchools: [
      'https://www.dekalbschools.net/',
      'https://www.greatschools.org/tennessee/smithville/',
    ],
  },
  {
    name: 'Soddy Daisy (Incorporated)',
    county: 'Hamilton County',
    zip: 37379,
    government: 'https://soddy-daisy.org/',
    demographics: 'https://data.census.gov/profile/Soddy-Daisy_city,_Tennessee?g=160XX00US4769560',
    electric: 'https://epb.com/',
    waterSewer: 'https://soddy-daisy.org/departments/public_works.php',
    cableInternet: 'https://epb.com/',
    gas: 'https://www.mtng.com/offices/dayton/',
    trashRemoval: 'https://soddy-daisy.org/departments/sanitation.php',
    publicSchools: [
      'https://sdhs.hcde.org/',
      'https://www.greatschools.org/tennessee/soddy_daisy/',
    ],
  },
  {
    name: 'South Cleveland',
    county: 'Bradley County',
    zip: 37311,
    government: 'https://clevelandtn.gov/',
    demographics: 'https://data.census.gov/profile/South_Cleveland_CDP,_Tennessee?g=160XX00US4769740',
    electric: 'https://www.clevelandutilities.com/',
    waterSewer: 'https://www.clevelandutilities.com/',
    cableInternet: 'https://www.clevelandutilities.com/stages-of-fiber-construction',
    gas: 'No Website Available',
    trashRemoval: 'https://clevelandtn.gov/183/Garbage-Solid-Waste',
    publicSchools: [
      'https://www.bradleyschools.org/',
      'https://www.greatschools.org/tennessee/cleveland/bradley-county-school-district/',
    ],
  },
  {
    name: 'South Pittsburg (Incorporated)',
    county: 'Marion County',
    zip: 37380,
    government: 'https://southpittsburgtn.org/',
    demographics: 'https://data.census.gov/profile/South_Pittsburg_city,_Tennessee?g=160XX00US4770060',
    electric: 'https://svalleyec.com/',
    waterSewer: 'https://www.southpittsburgutilities.com/',
    cableInternet: 'https://broadbandnow.com/SVEConnect',
    gas: 'No Website Located',
    trashRemoval: 'https://southpittsburgtn.org/?page_id=1190',
    publicSchools: [
      'https://www.southpittsburghigh.org/home/',
      'https://www.greatschools.org/tennessee/south-pittsburg/758-South-Pittsburg-High-School/',
    ],
  },
  {
    name: 'Sparta (Incorporated)',
    county: 'White County',
    zip: 38583,
    government: 'https://www.spartatn.gov/',
    demographics: 'https://data.census.gov/profile/Sparta_city,_Tennessee?g=160XX00US4770180',
    electric: 'http://spartaelectricsystem.com/',
    waterSewer: 'http://spartaelectricsystem.com/',
    cableInternet: 'https://benlomandconnect.com/?kbid=88449&utm_source=highspeedinternet.com&utm_medium=affiliate&clreqid=f89adebc-f7d9-47b8-aef3-cf1df259f01f&state=tn&city=sparta',
    gas: 'https://www.mtng.com/service-area/',
    trashRemoval: 'http://spartaelectricsystem.com/PublicWorks.htm',
    publicSchools: [
      'https://www.whitecoschools.net/',
      'https://www.greatschools.org/tennessee/sparta/',
    ],
  },
  {
    name: 'Spencer (Incorporated)',
    county: 'Van Buren County',
    zip: 38585,
    government: 'https://www.cityofspencertn.com/',
    demographics: 'https://data.census.gov/profile/Spencer_city,_Tennessee?g=160XX00US4770240',
    electric: 'https://www.caneyforkec.com/',
    waterSewer: 'https://www.cityofspencertn.com/departments/water/',
    cableInternet: 'Spectrum, STARLINK',
    gas: 'https://www.mtng.com/service-area/',
    trashRemoval: 'https://vanburencountytn.com/dept_services/index.html#solid',
    publicSchools: [
      'https://www.vanburenschools.org/',
      'https://www.greatschools.org/tennessee/spencer/',
    ],
  },
  {
    name: 'Spring City (Incorporated)',
    county: 'Rhea County',
    zip: 37381,
    government: 'https://www.townofspringcitytn.com/',
    demographics: 'https://data.census.gov/profile/Spring_City_town,_Tennessee?g=160XX00US4770400',
    electric: 'https://vec.org/locations/spring-city/',
    waterSewer: 'https://nudrc.org/',
    cableInternet: 'https://broadbandnow.com/Spring-City-Cable',
    gas: 'https://www.mtng.com/service-area/',
    trashRemoval: 'https://rheacountytn.org/relocation/recycling-centers/',
    publicSchools: [
      'https://www.rheacounty.org/',
      'https://www.greatschools.org/tennessee/spring-city/',
    ],
  },
  {
    name: 'Tracy City (Incorporated)',
    county: 'Grundy County',
    zip: 37387,
    government: 'https://www.grundycountytn.net/tracy_city/index.html',
    demographics: 'https://data.census.gov/profile/Tracy_City_town,_Tennessee?g=160XX00US4774880',
    electric: 'https://svalleyec.com/',
    waterSewer: 'https://tcpuws.nexbillpayonline.com/',
    cableInternet: 'https://benlomandconnect.com/',
    gas: 'https://www.mtng.com/offices/mcminnville/',
    trashRemoval: 'No Website Located',
    publicSchools: [
      'https://www.grundycoschools.com/',
      'https://www.greatschools.org/tennessee/tracy-city/364-Tracy-Elementary-School/',
    ],
  },
  {
    name: 'Walden (Incorporated)',
    county: 'Hamilton County',
    zip: 37377,
    government: 'https://www.waldentn.gov/',
    demographics: 'https://data.census.gov/profile/Walden_town,_Tennessee?g=160XX00US4777540',
    electric: 'https://www.tvec.com/',
    waterSewer: 'https://www.wrud.org/',
    cableInternet: 'EarthLink, AT&T, STARLINK',
    gas: 'No Website Available',
    trashRemoval: 'https://www.waldentn.gov/recycle-trash',
    publicSchools: [
      'https://www.hcde.org/',
    ],
  },
  {
    name: 'Whitwell (Incorporated)',
    county: 'Marion County',
    zip: 37397,
    government: 'https://cityofwhitwell.com/',
    demographics: 'https://data.census.gov/profile/Whitwell_city,_Tennessee?g=160XX00US4780620',
    electric: 'https://svalleyec.com/',
    waterSewer: 'https://www.countyoffice.org/whitwell-water-works-whitwell-tn-fc0/',
    cableInternet: 'Spectrum, AT&T, STARLINK',
    gas: 'No Website Located',
    trashRemoval: 'https://www.republicservices.com/locations/tennessee/whitwell-trash-pickup-and-recycling',
    publicSchools: [
      'https://www.marionschools.org/',
      'https://www.greatschools.org/tennessee/whitwell/760-Whitwell-High-School/',
    ],
  },
];

export default MiddleEastTNCities;
